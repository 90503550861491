import * as MicrosoftTeams from '@microsoft/teams-js';
import memo from 'memoize-one';

const isTeamsApp = memo(async () => {
  try {
    await MicrosoftTeams.app.initialize();
    const ctx = await MicrosoftTeams.app.getContext();
    const isTeams = ctx.app.host.name === MicrosoftTeams.HostName.teams
      || ctx.app.host.name === MicrosoftTeams.HostName.teamsModern;
    MicrosoftTeams.app._uninitialize();
    return isTeams;
  } catch (e) {
    return false;
  }
});

const isKioskApp = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return !!urlParams.get('kiosk');
};

const isPlugin = () => {
  return window.location.pathname.startsWith('/plugin');
};

const isMobileWebview = () => {
  return window.location.href.includes('mobile-webview');
};

const isDocView = () => {
  const href = window.location.href;
  return href.includes('terms-of-service') || href.includes('privacy-policy');
};

const isEmbeded = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return (
    window !== window.parent || // loaded in iframe
    urlParams.get('iframe') || // view forced as iframe
    urlParams.get('usertoken') || // usertoken only used when embeded
    (urlParams.get('user') && urlParams.get('token')) // old user token query params
  );
}

export const appMode = memo(async () => {
  switch (true) {
    case await isTeamsApp(): return 'teams';
    case isKioskApp(): return 'kiosk';
    case isMobileWebview(): return 'mobile-webview';
    case isDocView(): return 'doc-view';
    case isEmbeded(): return 'embeded';
    case isPlugin(): return 'plugin'; 
    default: return 'web';
  }
});

export async function appHasSideMenu () {
  return ['web'].includes(await appMode());
}

export async function appHasAngularTopMenu () {
  return ['teams'].includes(await appMode());
}

export async function appHasReactTopMenu () {
  return ['web', 'doc-view'].includes(await appMode());
}

// preload values
isTeamsApp();
